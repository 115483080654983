<template>
  <div ref="nav" @click="scroll">
    <!-- 导航栏 -->
    <div class="nav">
      <div class="center">
        <a href="https://www.xzreport.com"><img src="@/img/logo.png" alt="" /></a>
        <div class="right">
          <div class="nav_card">
            <div class="nav_list" @click="$router.push('/')"><span class="nav_title">首页</span></div>
            <div class="nav_list">
              <span class="nav_title">集成方案</span>
              <div class="hover_card">
                <ul style="padding: 0; margin: 0">
                  <li @click="$router.push('/qianru')"><span>报表控件嵌入方案</span></li>
                  <li @click="$router.push('/dayin')"><span>报表导出打印方案</span></li>
                  <li @click="$router.push('/zhongxin')"><span>报表中心搭建方案</span></li>
                </ul>
              </div>
            </div>
            <!-- <div class="nav_list" @click="$router.push('/vip')"><span class="nav_title">产品定价</span></div> -->
            <div class="nav_list">
              <span class="nav_title">产品下载</span>
              <div class="hover_card" style="left: -0.625rem">
                <ul style="padding: 0; margin: 0">
                  <li @click="goGitHub"><span>Github下载</span></li>
                  <li @click="goGitee"><span>码云下载</span></li>
                  <li @click="$router.push('/source')"><span>开发者资源</span></li>
                </ul>
              </div>
            </div>
            <div class="nav_list" @click="$router.push('/source')"><span class="nav_title">开发者资源</span></div>
            <div class="nav_list" @click="openHref"><span class="nav_title">帮助文档</span></div>
            <div class="nav_list tizdata" @click="openTizData"><span class="nav_title">天智数据</span></div>
          </div>

          <a href="https://designer.xzreport.com/" target="_blank" style="text-decoration: none"
            ><button class="use">免费使用</button></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: '1',
    };
  },
  computed: {
    heightTop() {
      console.log(this.$refs.nav.scrollTop);
    },
  },
  methods: {
    //导航条的回调
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      switch (key) {
        case '1':
          this.$router.push('/');
          break;
        case '2':
          window.open('https://www.yuque.com/tizdata/report/pdotp9hmrlo12vq5');
          break;
        case '5':
          window.open('https://www.tizdata.com', '_blank');
          break;
        case '3':
          this.$router.push('/video');
          break;
        case '4':
          this.$router.push('/source');
          break;
        case '6':
          this.$router.push('/again');
          break;
        default:
          break;
      }
    },
    openHref() {
      window.open('https://doc.tizdata.com/xiaozhi/557');
    },
    openTizData() {
      window.open('https://www.tizdata.com');
    },
    //导航条的滚动
    scroll() {
      console.log(this.$refs.nav.scrollTop);
    },
    //去使用小智报表
    goGitHub() {
      window.open('https://github.com/tianzhidata/XZReport', '_blank');
    },
    goGitee() {
      window.open('https://gitee.com/tizdata_admin/XZReport', '_blank');
    },
  },
  mounted() {
    // console.log(this.$router);
    // console.log(this.$route.path);
  },
  watch: {
    $route(to, from) {
      // console.log(to, from, 'to, from');
      this.activeIndex = to.path;
      // Do something when the route changes
    },
  },
};
</script>

<style lang="less">
template {
  font-family: 'PingFang SC' !important;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'PingFang SC' !important;
}

.nav {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  width: 100%;
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);

  .center {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 1200px;
    height: 60px;
    justify-content: center;
    background: #ffffff;

    // transform: translateX(-160px);
    // margin-left: 125px;
    @media screen and (max-width: 1050px) {
      margin-left: 0px;
    }

    @media (min-width: 1050px) {
      margin-left: 125px;
    }

    img {
      width: 145px;
      height: 40px;
      // margin-left: 160px;
      margin-right: 58px;
    }

    .right {
      display: flex;
      align-items: center;
      //  position: relative;

      .nav-title {
        font-size: 18px;
        line-height: 150%;
        color: #2b3361;
        margin-right: 0px;
      }

      .nav_card {
        height: 60px;
        //  border: 1px solid red;
        display: flex;
        align-items: center;

        .nav_list {
          position: relative;
          height: 100%;
          width: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          // border: 1px solid red;
          .nav_title {
            font-size: 16px;
            color: rgb(102, 102, 102);
          }

          .hover_card {
            display: none;
            position: absolute;
            top: 59px;
            left: -25px;
            //  width: 96px;
            margin: 0;
            border-radius: 4px;
            overflow: hidden;
            text-align: center;
            z-index: 99999;
            -webkit-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
            box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
            border: 1px solid rgba(0, 0, 0, 0.1);

            ul {
              li {
                list-style: none;
                height: 40px;
                line-height: 40px;
                font-size: 16px;
                background: #fff;
                cursor: pointer;
                padding-left: 20px;
                padding-right: 20px;

                span {
                  white-space: nowrap;
                  color: rgb(102, 102, 102);
                }
              }

              li:hover {
                background: rgb(245, 245, 245);

                span {
                  color: #000;
                  font-weight: 500;
                }
              }
            }
          }
        }

        .nav_list:hover {
          cursor: pointer;

          .nav_title {
            color: #000;
            font-weight: 500;
          }

          .hover_card {
            display: block;
          }

          background-color: #f5f5f5;
        }

        .tizdata {
          // background: #fff !important;
          span {
            color: rgb(64, 123, 255) !important;
            font-weight: 600 !important;
          }
        }
      }

      // margin-right: 378px;
    }

    .use {
      width: 104px;
      height: 41px;
      background: #407bff;
      border-radius: 4px;
      position: absolute;
      //  right: -650px;
      margin-left: 200px;
      top: 10px;
      font-size: 15px;
      line-height: 150%;
      border: none;
      color: #ffffff;
    }
  }
}

.el-menu--horizontal > .el-submenu .el-submenu__title {
  font-weight: 400;
  font-size: 18px;
  line-height: 60px;
  color: #2b3361;
}

.el-menu--collapse .el-submenu,
.el-menu-item {
  display: flex;
  align-items: center;
}

.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-color: #ffffff;
}
</style>
